<template>
  <v-container fluid class="full-fill pa-0">
    <v-row class="full-fill">
      <!-- Search -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
        <Search />
      </v-col>
      <!-- Content -->
      <v-col class="col-sm-7 ma-0 pa-5 pl-5 pr-5 pl-sm-0 pr-sm-0">
        <h1>News & Media</h1>
        <!-- Press Contacts -->
        <h4>Press Contacts</h4>
        <div><i class="fas fa-envelope mr-2"></i>info@honor-tag.com</div>
        <div class="mb-5"><i class="fas fa-phone-square-alt mr-2"></i>(202) 596-6181</div>
        <!-- News -->
        <h2 class="d-inline"><i class="fas fa-bullhorn mr-2"></i>News</h2>
        <v-row class="full-fill">
          <v-col v-for="(item, index) in newsItems" :key="index" class="col-sm-6 pa-0">
            <a :href="item.url" target="_blank" class="text-decoration-none">
              <v-card tile class="ma-5 mb-0 ml-sm-0 mr-sm-0" :class="index % 2 === 0 ? 'mr-sm-2' : 'ml-sm-2'">
                <v-card-title style="word-break: keep-all">{{ item.title }}</v-card-title>
                <v-card-text>{{ item.outlet }} | {{ item.date }}</v-card-text>
                <v-card-text>{{ item.desc }}</v-card-text>
              </v-card>
            </a>
          </v-col>
        </v-row>
        <!-- Media -->
        <h2 class="mt-5"><i class="fas fa-photo-video mr-2"></i>Media</h2>
        <v-row class="full-fill">
          <v-col v-for="(media, index) in mediaItems" :key="index" class="col-sm-6 pa-0">
            <v-card tile class="ma-5 mb-0 ml-sm-0 mr-sm-0" :class="index % 2 === 0 ? 'mr-sm-2' : 'ml-sm-2'">
              <v-card-title style="word-break: keep-all">{{ media.title }}</v-card-title>
              <v-img :src="require('@/assets/img/media/' + media.file + '.jpg')"></v-img>
              <v-card-actions align="center">
                <a :href="'./media/' + media.file + '.jpg'" target="_blank" style="text-decoration: none;">
                  <v-btn class="blue darken-3 white--text">
                    Download
                  </v-btn>
                </a>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!-- Logos -->
        <h2 class="mt-8"><i class="fas fa-palette mr-2"></i>Logos and Icons</h2>
        <v-row class="full-fill">
          <v-col v-for="(item, i) in pressItemFiles" :key="i" class="col-sm-6 pa-0">
            <v-card tile class="ma-5 mb-0 ml-sm-0 mr-sm-2">
              <v-card-title style="word-break: keep-all">{{ pressItemNames[i] }}</v-card-title>
              <v-col align="center">
                <v-img max-width="300" :src="require(`@/assets/press/${item}.png`)"></v-img>
              </v-col>
              <v-card-actions align="center">
                <a :href="`./media/press/${item}.png`" target="_blank" style="text-decoration: none;">
                  <v-btn class="blue darken-3 white--text">
                    Download
                  </v-btn>
                </a>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Ads -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Search from '@/components/Search'

  export default {
    name: 'NewsMedia',

    components: {
      Search
    },

    data () {
      return {
        awardBranchFilters: [],
        isShowingImageModal: false,
        mediaItems: require('@/assets/data/media-items.json'),
        newsItems: require('@/assets/data/news-items.json'),
        pressItemFiles: [
          'FullLogo_3254x465',
          'Square_MultiDark_BG',
          'logo-icon',
          'flyer_1.1.1',
          'banner'
        ],
        pressItemNames: [
          'Main Logo',
          'Square Logo',
          'Icon',
          'Banner',
          'Flyer'
        ],
        selectedImageURL: ''
      }
    }
  }
</script>

<style scoped>
  .full-fill {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
</style>
